<template>
  <div class="white rounded-md pa-4">
    <v-list class="pa-0">
      <div class="text-h3 font-weight-bold secondary--text mb-3">
        {{ $t("lists.header.lists") }}
      </div>
      <div class="text-caption">
        <v-list-item :to="{ name: 'List', params: { listId: 'preferred' } }">
          <i18n
            :path="hasFavorites ? 'list.preferred' : 'list.bestselling'"
            tag="strong"
          />
        </v-list-item>
        <v-list-item
          v-for="list in listsData"
          :key="list.id"
          :class="{
            default: list.isDefault,
            'secondary--text': list.isDefault
          }"
          :to="{ name: 'List', params: { listId: list.id } }"
        >
          <v-row no-gutters align="center" v-if="!list.isEdit">
            <v-col cols="6" md="7">
              <strong class="name">{{ `${list.name} (${list.size})` }}</strong>
            </v-col>
            <v-col
              cols="6"
              md="5"
              class="d-flex justify-end justify-content-end align-center"
            >
              <v-tooltip left v-if="!list.isDefault">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    v-on:click.prevent="setDefault(list.id)"
                    class="secondary--text"
                  >
                    <v-icon>$ratingEmpty</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("lists.button.setDefault") }}</span>
              </v-tooltip>
              <v-tooltip left v-if="list.isDefault">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    v-on:click.prevent=""
                    class="secondary--text"
                  >
                    <v-icon>$ratingFull</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("lists.button.default") }}</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    v-on:click.prevent="setEdit(list)"
                  >
                    <v-icon small>$edit</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("lists.button.edit") }}</span>
              </v-tooltip>

              <v-tooltip left v-if="!list.isDefault">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    v-on:click.prevent="removeList(list.id, list.name)"
                  >
                    <v-icon small color="secondary">$delete</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("lists.button.delete") }}</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <v-row no-gutters align="center" v-if="list.isEdit">
            <!-- label="Inserisci il nome" -->
            <v-col cols="8">
              <v-text-field
                hide-details="auto"
                dense
                outlined
                class="text-body-2 pr-2"
                v-model="list.tempName"
                v-on:click.prevent="() => {}"
              ></v-text-field>
            </v-col>
            <v-col cols="4" class="d-flex flex-row">
              <v-btn icon v-on:click.prevent="confirmEdit(list)">
                <v-icon small color="primary">$complete</v-icon>
              </v-btn>
              <v-btn icon v-on:click.prevent="undoEdit(list)">
                <v-icon small color="secondary">$close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-list-item>
      </div>
    </v-list>

    <div class="text-body-2 text-uppercase font-weight-bold mt-5 mb-2">
      <strong>{{ $t("lists.header.create") }}</strong>
    </div>

    <div class="d-flex flex-row mb-3 align-center">
      <v-text-field
        :placeholder="$t('lists.insert')"
        hide-details="auto"
        class="text-body-2 pr-2"
        dense
        outlined
        v-model="listName"
      ></v-text-field>
      <v-btn
        v-ripple="false"
        depressed
        class="primary"
        height="40"
        v-on:click="createList()"
        :disabled="createDisabled"
        >{{ $t("lists.button.create") }}</v-btn
      >
    </div>
  </div>
</template>
<style scoped lang="scss">
.v-list-item {
  padding: 0;
  border-bottom: 1px solid $gray-border-color !important;
  &.default {
    background-color: white !important;
    &:before {
      opacity: 0;
    }
  }
  &.v-list-item--active {
    background-color: white !important;
    &:before {
      opacity: 0;
    }
  }
  &:last-of-type {
    border-bottom: none !important;
  }
}
</style>
<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import list from "~/mixins/list";

export default {
  name: "ListsMenu",
  mixins: [list],
  props: {
    listsData: { type: Array, required: true }
  },
  computed: {
    ...mapGetters({
      hasFavorites: "cart/hasFavorites"
    })
  },
  methods: {
    ...mapActions({
      addProductsFromWishlist: "cart/addProductsFromWishlist"
    }),
    createList() {
      let name = this.listName;
      this.$emit("createlist", { name });
    },
    setDefault(listId) {
      this.$emit("setdefault", listId);
    },
    removeList(listId, listName) {
      this.$emit("remove", { listId, listName });
    },
    setEdit(list) {
      Vue.set(list, "isEdit", true);
      Vue.set(list, "tempName", list.name);
    },
    undoEdit(list) {
      list.isEdit = false;
      list.tempName = null;
    },
    confirmEdit(list) {
      this.$emit("rename", list);
    },
    async addAllToCart(list) {
      let res = await global.vm.$dialog.confirm({
        text: global.vm.$t("message.addAllToList")
      });
      if (res) {
        await this.addProductsFromWishlist(list.id);
      }
    },
    setOpenList(listId) {
      console.log(listId);
      this.setOpenedListId(listId);
    }
  },
  created() {
    console.log(this);
  }
  // watch: {
  //   lists() {
  //     console.log(this.lists);
  //   }
  // }
};
</script>
