<template>
  <div class="scroll-container">
    <CategoryTitle :category="category" />
    <v-row class="list-page">
      <v-col class="relative" cols="12" md="9">
        <!-- pagina principale: dettaglio lista -->
        <ListPreferred v-if="listId == 'preferred'" />
        <ListDetail
          v-else-if="listId != null"
          :listId="listId"
          :showSectors="true"
        />
        <v-speed-dial
          v-if="$vuetify.breakpoint.smAndDown"
          v-model="fab"
          :right="true"
          persistent
          direction="top"
          transition="slide-y-reverse-transition"
          class="lists-filter"
        >
          <template v-slot:activator>
            <v-btn v-model="fab" color="secondary" fab>
              <v-icon v-if="fab">
                $close
              </v-icon>
              <v-icon v-else>
                $menu
              </v-icon>
            </v-btn>
          </template>
          <v-card @click.stop.prevent="" class="rounded-br-xl" :ripple="false">
            <ListsMenu
              :listsData="lists"
              @setdefault="setDefaultList"
              @createlist="createListt"
              @remove="removeList"
              @rename="renameList"
            />
          </v-card>
        </v-speed-dial>
      </v-col>
      <v-col class="hidden-sm-and-down pl-0" cols="3">
        <ListsMenu
          v-if="!$vuetify.breakpoint.sm"
          :listsData="lists"
          @setdefault="setDefaultList"
          @remove="removeList"
          @createlist="createListt"
          @rename="renameList"
        />

        <v-card
          light
          outlined
          v-if="lists && lists.length == 0"
          class="pa-2 text-center"
          style="width:100%;"
        >
          <v-card-title class="headline">Nessuna lista trovata</v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped lang="scss">
.v-speed-dial {
  position: fixed;
  bottom: 80px;
}
.lists-filter.v-speed-dial--is-active {
  ::v-deep .v-speed-dial__list {
    .v-card {
      overflow-y: auto;
      max-height: calc(100vh - 250px);
      border-radius: $border-radius-root;
      border: 1px solid var(--v-grey-lighten1);
      -webkit-box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.6);
      box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.6);
    }
  }
}
</style>
<script>
// @ is an alias to /src
// import ProfileTitle from "@/components/profile/ProfileTitle";

import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ListsMenu from "@/components/lists/ListsMenu.vue";
import ListDetail from "@/components/lists/ListDetail.vue";
import ListPreferred from "@/components/lists/ListPreferred.vue";

import list from "~/mixins/list";
import categoryMixins from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";

export default {
  name: "Lists",
  mixins: [list, categoryMixins, deliveryReactive],
  data() {
    return {
      page: {},
      listId: null,
      fab: false,
      prof: false
    };
  },
  components: {
    CategoryTitle,
    ListsMenu,
    ListDetail,
    ListPreferred
  },
  methods: {
    checkListId() {},
    createListt(obj) {
      this.listName = obj.name;
      this.createList();
    }
  },
  created() {
    this.reload();
  },
  watch: {
    lists() {
      if (this.$route && this.$route.params && !this.$route.params.listId) {
        for (var i = 0; i < this.lists.length; i++) {
          if (this.lists[i].isDefault) {
            this.listId = this.lists[i].id;
            this.$router.push({
              name: "List",
              params: { listId: this.listId }
            });
          }
        }
      } else {
        this.listId = parseInt(this.$route.params.listId);
      }
    },
    $route(to) {
      this.listId = to.params.listId;
    }
  }
};
</script>
